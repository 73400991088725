import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Typography,
  Card,
  CardContent,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from "@material-ui/core";
import swal from "sweetalert";
import apiClient from "../../api/client";

const OrderDetails = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const orderId = pathname.split("/").pop();
  const [order, setOrder] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [deliveryStatus, setDeliveryStatus] = useState("");

  useEffect(() => {
    const fetchOrderDetails = async () => {
      const result = await apiClient.get(`/order/${orderId}`);
      if (result.ok) {
        setOrder(result.data);
        setDeliveryStatus(result.data.deliveryStatus);  // Set initial status
        setIsLoading(false);
      } else {
        swal("Error", "Failed to fetch order details", "error");
        navigate("/orders");
      }
    };

    fetchOrderDetails();
  }, [orderId, navigate]);

  const handleDeleteOrder = async () => {
    const confirmation = await swal({
      title: "Are you sure?",
      text: "This will delete the order.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });

    if (confirmation) {
      const result = await apiClient.delete(`/order/delete-order`, {
        id: order._id
      });
   
      if (result.ok) {
        swal("Success", "Order deleted successfully", "success");
        navigate("/orders/list");
      } else {
        swal("Error", "Failed to delete order", "error");
      }
    }
  };

  const handleStatusChange = async (event) => {
    const newStatus = event.target.value;
    setDeliveryStatus(newStatus); 

    const result = await apiClient.post(`/order/update`, {
      orderId: order._id,
      deliveryStatus: newStatus,
    });

    if (result.ok) {
      swal("Success", "Delivery status updated successfully", "success");
    } else {
      swal("Error", "Failed to update delivery status", "error");
    }
  };

  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid">
          <div className="row mb-4">
            <div className="col-lg-6 col-md-6">
              <h2 className="page-title">Order Details</h2>
            </div>
            <div className="col-lg-6 col-md-6 text-right">
              <Button variant="contained" onClick={() => navigate("/")}>
                <i className="fas fa-arrow-left" /> Back
              </Button>
            </div>
          </div>
          {isLoading ? (
            <Typography>Loading...</Typography>
          ) : (
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <Card className="mb-4">
                  <CardContent>
                    <Typography variant="h6">Shipping Information</Typography>
                    <Typography>Address: {order.shippingAddress.address}</Typography>
                    <Typography>Street: {order.shippingAddress.street}</Typography>
                    <Typography>Landmark: {order.shippingAddress.landmark}</Typography>
                    <Typography>City: {order.shippingAddress.city}</Typography>
                    <Typography>Mobile Number: {order.shippingAddress.mobileNumber}</Typography>
                    <Typography>Email: {order.shippingAddress.email}</Typography>
                  </CardContent>
                </Card>
                <Card>
                  <CardContent>
                    <Typography variant="h6">Customer Information</Typography>
                    <Typography>Name: {order.user.name}</Typography>
                    <Typography>Email: {order.shippingAddress.email}</Typography>
                    
                    {/* Delivery Status Dropdown */}
                    <FormControl fullWidth margin="normal">
                      <InputLabel>Delivery Status</InputLabel>
                      <Select
                        value={deliveryStatus}
                        onChange={handleStatusChange}
                      >
                        <MenuItem value="Processing">Processing</MenuItem>
                        <MenuItem value="Out for Delivery">Out for Delivery</MenuItem>
                        <MenuItem value="Delivered">Delivered</MenuItem>
                        <MenuItem value="Cancelled">Cancelled</MenuItem>
                      </Select>
                    </FormControl>
                  </CardContent>
                </Card>
              </div>

              <div className="col-lg-6 col-md-12">
                <Card className="mb-4">
                  <CardContent>
                    <Typography variant="h6">Order Summary</Typography>
                    <Typography>Total Price: ${order.totalPrice}</Typography>
                    <Typography>Payment Method: {order.paymentMethod}</Typography>
                    <Typography>Order Status: {deliveryStatus}</Typography>
                    <Typography>Order Date: {new Date(order.createdAt).toLocaleDateString()}</Typography>
                    <Button
                      variant="contained"
                      color="secondary"
                      className="mt-2"
                      onClick={handleDeleteOrder}
                    >
                      Delete Order
                    </Button>
                  </CardContent>
                </Card>
              </div>

              <div className="col-lg-12">
                <Card>
                  <CardContent>
                    <Typography variant="h6">Order Items</Typography>
                    <table className="table ucp-table table-hover mt-2">
                      <thead>
                        <tr>
                          <th>Product Name</th>
                          <th>Quantity</th>
                          <th>Unit Price</th>
                          <th>Final Price</th>
                          <th>Product Image</th>
                        </tr>
                      </thead>
                      <tbody>
                        {order.orderItems.map((item) => (
                          <tr key={item._id}>
                            <td>{item.name}</td>
                            <td>{item.qty}</td>
                            <td>${item.price}</td>
                            <td>${item.finalprice}</td>
                            <td>
                              <img
                                src={item.image}
                                alt={item.name}
                                style={{ width: "50px", height: "50px" }}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </CardContent>
                </Card>
              </div>
            </div>
          )}
        </div>
      </main>
    </div>
  );
};

export default OrderDetails;
