
import React from "react";
import "./widget.scss";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";

const Widget = ({ type, count }) => {
  const iconMap = {
    ecommerce: {
      title: "Total Ecommerce Vendors",
      icon: (
        <ShoppingCartOutlinedIcon
          className="icon"
          style={{ color: "crimson", backgroundColor: "rgba(255, 0, 0, 0.2)" }}
        />
      ),
    },
    company: {
      title: "Total Company Vendors",
      icon: (
        <PersonOutlinedIcon
          className="icon"
          style={{
            backgroundColor: "rgba(218, 165, 32, 0.2)",
            color: "goldenrod",
          }}
        />
      ),
    },
    property: {
      title: "Total Property ",
      icon: (
        <MonetizationOnOutlinedIcon
          className="icon"
          style={{ backgroundColor: "rgba(0, 128, 0, 0.2)", color: "green" }}
        />
      ),
    },
    maintenance: {
      title: "Total Maintenance Vendors",
      icon: (
        <AccountBalanceWalletOutlinedIcon
          className="icon"
          style={{
            backgroundColor: "rgba(128, 0, 128, 0.2)",
            color: "purple",
          }}
        />
      ),
    },
    unapproved: {
      title: "Total Unapproved Sellers",
      icon: (
        <MonetizationOnOutlinedIcon
          className="icon"
          style={{ color: "crimson", backgroundColor: "rgba(255, 0, 0, 0.2)" }}
        />
      ),
    },
    users: {
      title: "Total Customers",
      icon: (
        <PersonOutlinedIcon
          className="icon"
          style={{ color: "crimson", backgroundColor: "rgba(255, 0, 0, 0.2)" }}
        />
      ),
    },
    properties: {
      title: "Total Properties Listing",
      icon: (
        <MonetizationOnOutlinedIcon
          className="icon"
          style={{ backgroundColor: "rgba(0, 128, 0, 0.2)", color: "green" }}
        />
      ),
    },
     totalSales: {
      title: "Total Sales",
      icon: (
        <MonetizationOnOutlinedIcon
          className="icon"
          style={{ backgroundColor: "rgba(0, 128, 0, 0.2)", color: "green" }}
        />
      ),
    },
    product: {
      title: "Total Products Count",
      icon: (
        <ShoppingCartOutlinedIcon
          className="icon"
          style={{ color: "crimson", backgroundColor: "rgba(255, 0, 0, 0.2)" }}
        />
      ),
    },
    enquiries: {
      title: "Total Enquiries Count",
      icon: (
        <ShoppingCartOutlinedIcon
          className="icon"
          style={{ color: "crimson", backgroundColor: "rgba(255, 0, 0, 0.2)" }}
        />
      ),
    },
    categories: {
      title: "MyCategory Count",
      icon: (
        <ShoppingCartOutlinedIcon
          className="icon"
          style={{ color: "crimson", backgroundColor: "rgba(255, 0, 0, 0.2)" }}
        />
      ),
    },
  
  };

  const { title, icon } = iconMap[type] || {};

  return (
    <div className="widget">
      <div className="left">
        <span className="title">{title}</span>
        <span className="counter">
          {count} 
        </span>
      </div>
      <div className="right">
        {icon}
      </div>
    </div>
  );
};

export default Widget;
