import { Button } from "@material-ui/core";
import React from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import apiClient from "./../../api/client";
import swal from "sweetalert";

function SendNotificationAll() {
  const location = useLocation();


  const navigate = useNavigate();

  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [image, setImage] = useState("");

  const handleSubmit = async () => {
    if (image) {
      const formData = new FormData();

      formData.append("image", image, image.name);

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const { data } = await apiClient.post(
        "/upload/uploadSingleImage",
        formData,
        config
      );
      const result = await apiClient.post("/send/send-notification-all", {
      
        title,
        body,
        image: data,
      });

      if (!result.ok) swal("Notifiction sent error");
      else {
        swal("Success");
        setTitle("");
        setBody("");
        setImage("");
      }
    } else {
      const result = await apiClient.post("/send/send-notification-all", {
     
        title,
        body,
      
      });

      if (!result.ok) swal("Notifiction sent error");
      else {
        swal("Success");
        setTitle("");
        setBody("");
        setImage("");
      }
    }
  };
  const uploadFileHandler = async (e) => {
    setImage(e.target.files[0]);
  };

  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-5 col-md-9 col-lg-6">
              <h2 className="mt-30 page-title">🔔 Notifications to All</h2>
            </div>
            <div className="col-lg-5 col-md-3 col-lg-6 back-btn">
              <Button
                variant="contained"
                onClick={(e) => navigate("/users/list")}
              >
                <i class="fas fa-arrow-left" /> Back
              </Button>
            </div>
          </div>
          <ol className="breadcrumb mb-30">
            <li className="breadcrumb-item">
              <a href="/">Dashboard</a>
            </li>
            <li className="breadcrumb-item">
              <a href="/admin/product/create">Users</a>
            </li>
            <li className="breadcrumb-item active">Notification</li>
          </ol>

          {/* Add category  */}
        
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="card card-static-2 mb-30">
                  <div className="card-title-2">
                    <h4>Send Notification</h4>
                  </div>
                  <div className="card-body-table">
                    <div className="news-content-right pd-20">
                      <div className="col-lg-3 col-md-2">
                        <div className="form-group">
                          <label className="form-label">
                            Notification title
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Title"
                            name="title"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                          />
                        </div>
                      </div>

                      <br />
                      <div className="col-lg-6 col-md-2 ">
                        <div className="form-group">
                          <label className="form-label">Description</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Description"
                            name="body"
                            value={body}
                            onChange={(e) => setBody(e.target.value)}
                          />
                        </div>
                      </div>
                      <br />
                      <div className="col-lg-3 col-md-2">
                        <div className="form-group">
                          <label className="form-label">
                            Select Image for Notification
                          </label>
                          <form>
                            <input type="file" onChange={uploadFileHandler} />
                          </form>
                        </div>
                      </div>

                      <button
                        className="save-btn hover-btn"
                        type="submit"
                        onClick={handleSubmit}
                      >
                        Send
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          
        </div>
      </main>
    </div>
  );
}

export default SendNotificationAll;
