import React, { useState } from "react";

import swal from "sweetalert";
import {
  Autocomplete,
  BottomNavigationAction,
  Box,
  Fab,
  Grid,
  InputLabel,
  TextField,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import apiClient from "./../../api/client";

const AddEcomCategory = () => {

  const [name, setName] = useState();
  const [nameAr, setNameAr] = useState();
  const [photo, setPhoto] = useState();

  const [modal, setModal] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    swal({
      title: "Are you sure?",
      text: "You want to Add New Category for maintenance?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (success) => {
      if (success) {
        setModal(true);
        const formData = new FormData();
    

        formData.append("image", photo, photo.name);
      

        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };

        const { data } = await apiClient.post(
          "/upload/uploadSingleImage",
          formData,
          config
        );

        const d1 = data;

      

        createCategory({ d1 });
      }
    });
  };

  const createCategory = async ({ d1 }) => {
    const result = await apiClient.post("/maintenance/category", {
      
      name,
      nameAr,
      image: d1,
    });
    if (result.ok) {
      setModal(false);
  
      setName("");
      setNameAr("");
      setPhoto("");
      swal("Sucess");
      navigate("/maintenance/category/list");
    } else {
      setModal(false);
      swal("Error Retry!");
    }
  };

  const navigate = useNavigate();

  const uploadFileHandler = async (e) => {
    setPhoto(e.target.files[0]);
  };


  return (
    <div id="layoutSidenav_content">
      {modal ? (
        <div
          style={{ textAlign: "center", marginTop: "250px", fontSize: "25px" }}
        >
          Loading... Please wait!
        </div>
      ) : (
        <main>
            <div className="row">
              <div className="col-lg-5 col-md-9 col-lg-6">
                <h2 className="mt-30 page-title">Maintenance Category</h2>
              </div>
              <div className="col-lg-5 col-md-3 col-lg-6 back-btn">
                <Button
                  variant="contained"
                  onClick={(e) => navigate("/maintenance/category/list")}
                >
                  <i class="fas fa-arrow-left" /> Back
                </Button>
              </div>
            </div>
            <ol className="breadcrumb mb-30">
              <li className="breadcrumb-item">
                <a href="/">Dashboard</a>
              </li>
              <li className="breadcrumb-item active">Add Category</li>
            </ol>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <Grid container mx={1} my={1.5} spacing={2}>
              <Grid item xs={3}>
                <TextField
                  name="name"
                  required
                  fullWidth
                  size="small"
                  id="Name"
                  label="Name"
                  onChange={(e) => setName(e.target.value)}
                />
              </Grid>
            </Grid>

            <Grid container ml={1} my={1.5} spacing={2}>
              <Grid item xs={4}>
                <TextField
                  name="nameAr"
                  required
                  fullWidth
                  size="small"
                  id="nameAr"
                  label="Name in Arabic"
                  onChange={(e) => setNameAr(e.target.value)}
                />
              </Grid>
            </Grid>

            <Grid container ml={2} mt={2.5} spacing={2}>
              <Grid item xs={10}>
                <input type="file" multiple onChange={uploadFileHandler} />
              </Grid>
            </Grid>
            <Grid sx={4}>
              <Button
                type="submit"
                variant="contained"
                sx={{ ml: 3, mt: 2, mb: 2 }}
              >
                Add Category
              </Button>
            </Grid>
          </Box>
        </main>
      )}
    </div>
  );
};

export default AddEcomCategory;
