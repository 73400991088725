import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import { Box, Grid, TextField, Button, InputLabel } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import apiClient from "../../api/client";

const EditEcomBrand = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const brandData = location.state;

  const [name, setName] = useState(brandData?.name || "");
  const [nameAr, setNameAr] = useState(brandData?.nameAr || "");
  const [photo, setPhoto] = useState(null);
  const [modal, setModal] = useState(false);

  useEffect(() => {
    setName(brandData?.name);
    setNameAr(brandData?.nameAr);
  }, [brandData]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    swal({
      title: "Are you sure?",
      text: "You want to update this brand?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (success) => {
      if (success) {
        setModal(true);
        const formData = new FormData();

        if (photo) {
          formData.append("image", photo, photo.name);
          const { data } = await apiClient.post(
            "/upload/uploadSingleImage",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          updateBrand({ image: data });
        } else {
          updateBrand({ image: brandData?.image });
        }
      }
    });
  };

  const updateBrand = async ({ image }) => {
    const result = await apiClient.post(`/ecom/brands/update`, {
      id: brandData._id,
      name,
      nameAr,
      image,
    });
   console.log(result)
    if (result.ok) {
      setModal(false);
      swal("Success! Brand updated");
      navigate("/product/brand/list");
    } else {
      setModal(false);
      swal("Error! Retry updating brand");
    }
  };

  const uploadFileHandler = (e) => {
    setPhoto(e.target.files[0]);
  };

  return (
    <div id="layoutSidenav_content">
      {modal ? (
        <div style={{ textAlign: "center", marginTop: "250px", fontSize: "25px" }}>
          Loading... Please wait!
        </div>
      ) : (
        <main>
          <div className="row">
            <div className="col-lg-5 col-md-9 col-lg-6">
              <h2 className="mt-30 page-title">Edit Brand</h2>
            </div>
            <div className="col-lg-5 col-md-3 col-lg-6 back-btn">
              <Button variant="contained" onClick={() => navigate("/product/brand/list")}>
                <i className="fas fa-arrow-left" /> Back
              </Button>
            </div>
          </div>
          <ol className="breadcrumb mb-30">
            <li className="breadcrumb-item">
              <a href="/">Dashboard</a>
            </li>
            <li className="breadcrumb-item active">Edit Brand</li>
          </ol>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <Grid container mx={1} my={1.5} spacing={2}>
              <Grid item xs={3}>
                <TextField
                  name="name"
                  required
                  fullWidth
                  size="small"
                  id="Name"
                  label="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Grid>
            </Grid>

            <Grid container ml={1} my={1.5} spacing={2}>
              <Grid item xs={4}>
                <TextField
                  name="nameAr"
                  required
                  fullWidth
                  size="small"
                  id="nameAr"
                  label="Name in Arabic"
                  value={nameAr}
                  onChange={(e) => setNameAr(e.target.value)}
                />
              </Grid>
            </Grid>

            <Grid container ml={2} mt={2.5} spacing={2}>
              <Grid item xs={10}>
                <InputLabel>Upload New Image (optional)</InputLabel>
                <input type="file" onChange={uploadFileHandler} />
              </Grid>
            </Grid>
            {brandData?.image && !photo && (
              <Grid container mt={2} spacing={2}>
                <Grid item xs={10}>
                  <img src={brandData.image} alt="Current" style={{ width: "100px" }} />
                </Grid>
              </Grid>
            )}
            <Grid sx={4}>
              <Button type="submit" variant="contained" sx={{ ml: 3, mt: 2, mb: 2 }}>
                Update Brand
              </Button>
            </Grid>
          </Box>
        </main>
      )}
    </div>
  );
};

export default EditEcomBrand;
