import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

function Chart({ monthlySales }) {
  const [series, setSeries] = useState([]);

  useEffect(() => {
    const monthlyData = Array(12).fill(0);  

    monthlySales.forEach(({ _id, monthlySales }) => {
      monthlyData[_id - 1] = monthlySales;
    });

    setSeries([{ name: "Monthly Sales", data: monthlyData }]);
  }, [monthlySales]);

  const options = {
    chart: {
      type: "bar",
      height: 350,
    },
    xaxis: {
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    },
    yaxis: {
      labels: {
        show: true  
      },
    },
    tooltip: {
      y: {
       
      },
    },
  };

  return (
    <div style={{ backgroundColor: "white", textAlign: "center", width: "90%", margin: "10px auto", padding: "10px" }}>
      <h3>Monthly Sales</h3>
      <ReactApexChart options={options} series={series} type="bar" height={350} />
    </div>
  );
}

export default Chart;
