import React, { useState, useEffect, useRef, useMemo } from "react";
// import { Button } from "@material-ui/core";
import swal from "sweetalert";
import apiClient from "../../api/client";
import { Category, UploadFileSharp } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import {
  Autocomplete,
  BottomNavigationAction,
  Box,
  Fab,
  Grid,
  InputLabel,
  TextField,
  Button,
} from "@mui/material";

import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { QuillFormats } from "../../components/editor/quill";
import useAuth from "../../auth/useAuth";
const CompanyDetails = () => {
  const { user } = useAuth();
  const [list, setList] = useState();
  useEffect(() => {
    fetchProfile();
  }, []);
  const fetchProfile = async () => {
    const { data } = await apiClient.get("/directory/get-profile", {
      id: user.id,
    });

    setList(data);
    setWebsite(data?.website);
    setDetails(data?.details);
    setDescription(data?.description);
  };
  const quillRef = useRef();
  const [description, setDescription] = useState();
  const [details, setDetails] = useState();

  const [file2, setFile2] = useState([]);
  const [website, setWebsite] = useState("");
  const [address, setAddress] = useState("");

  const navigate = useNavigate();

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ["bold", "italic", "underline", "strike"],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          ["link"],
          [
            {
              color: [
                "#000000",
                "#e60000",
                "#ff9900",
                "#ffff00",
                "#008a00",
                "#0066cc",
                "#9933ff",
                "#ffffff",
                "#facccc",
                "#ffebcc",
                "#ffffcc",
                "#cce8cc",
                "#cce0f5",
                "#ebd6ff",
                "#bbbbbb",
                "#f06666",
                "#ffc266",
                "#ffff66",
                "#66b966",
                "#66a3e0",
                "#c285ff",
                "#888888",
                "#a10000",
                "#b26b00",
                "#b2b200",
                "#006100",
                "#0047b2",
                "#6b24b2",
                "#444444",
                "#5c0000",
                "#663d00",
                "#666600",
                "#003700",
                "#002966",
                "#3d1466",
              ],
            },
          ],
        ],
      },
    }),
    []
  );

  const uploadFileHandler = async (e) => {
    setFile2([...file2, ...e.target.files]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    swal({
      title: "Are you sure?",
      text: "You want to Add New Product",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (success) => {
      if (success) {
        const img = await handlesubmit2();

        const result = await apiClient.post("/directory/update-profile", {
          id: user.id,
          website,
          address,
          description,
          details,
          image: img,
        });
        if (result.ok) {
          swal("Success");
          navigate("/");
        }
      }
    });
  };

  const handlesubmit2 = async () => {
    if (file2) {
      const formData = new FormData();

      for (const file of file2) {
        formData.append("image", file, file.name);
      }

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const result = await apiClient.post(
        "/upload/uploadMultiple",
        formData,
        config
      );

      if (result.ok) {
        setFile2([]);
        return result.data;
      } else {
        swal("Error Retry!");
      }
    }
  };

  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-5 col-md-9 col-lg-6">
              <h2 className="mt-30 page-title">Profile</h2>
            </div>
            <div className="col-lg-5 col-md-3 col-lg-6 back-btn">
              <Button variant="contained" onClick={(e) => this.handleBack()}>
                <i class="fas fa-arrow-left" /> Back
              </Button>
            </div>
          </div>
          <ol className="breadcrumb mb-30">
            <li className="breadcrumb-item">
              <a href="/">Dashboard</a>
            </li>

            <li className="breadcrumb-item active">Update Profile</li>
          </ol>

          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <Grid container my={1.5} spacing={2}>
              <Grid item xs={3}>
                <TextField
                  name="website"
                  required
                  fullWidth
                  size="small"
                  id="website"
                  value={website}
                  label="Website"
                  onChange={(e) => setWebsite(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="address"
                  required
                  fullWidth
                  size="small"
                  id="address"
                  value={address}
                  label="Company Address"
                  onChange={(e) => setAddress(e.target.value)}
                />
              </Grid>
            </Grid>

            <Grid container my={1.5} spacing={2}>
              <Grid item xs={10}>
                <InputLabel sx={{ fontWeight: 600 }}>Description</InputLabel>
                <ReactQuill
                  theme="snow"
                  formats={QuillFormats}
                  ref={quillRef}
                  value={description}
                  modules={modules}
                  onChange={setDescription}
                  placeholder="Write description..."
                />
              </Grid>
            </Grid>
            <Grid container my={1.5} spacing={2}>
              <Grid item xs={10}>
                <InputLabel sx={{ fontWeight: 600 }}>Details</InputLabel>
                <ReactQuill
                  theme="snow"
                  formats={QuillFormats}
                  ref={quillRef}
                  value={details}
                  modules={modules}
                  onChange={setDetails}
                  placeholder="Write Details..."
                />
              </Grid>
            </Grid>

            <Grid container mt={2.5} spacing={2}>
              <Grid item xs={10}>
                <input type="file" multiple onChange={uploadFileHandler} />
              </Grid>
            </Grid>
            <Grid sx={4}>
              <Button type="submit" variant="contained" sx={{ mt: 2, mb: 2 }}>
                Update Profile
              </Button>
            </Grid>
          </Box>
        </div>
      </main>
    </div>
  );
};

export default CompanyDetails;
