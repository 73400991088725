import React, { useState } from "react";

import swal from "sweetalert";
import {
  Autocomplete,
  BottomNavigationAction,
  Box,
  Fab,
  Grid,
  InputLabel,
  TextField,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import apiClient from "./../../api/client";

const AddArea = () => {

  const [name, setName] = useState();
  const [nameAr, setNameAr] = useState();
  

  const [modal, setModal] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    swal({
      title: "Are you sure?",
      text: "You want to Add New Area ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (success) => {
      if (success) {
        const result = await apiClient.post("/misc/area", {
      
            name,
nameAr
          });
          if (result.ok) {
            setModal(false);
        
            setName("");
            setNameAr("");
         
            swal("Sucess");
            navigate("/property/location/");
          } else {
            setModal(false);
            swal("Error Retry!");
          }
      }
    });
  };

  

  const navigate = useNavigate();

 


  return (
    <div id="layoutSidenav_content">
      {modal ? (
        <div
          style={{ textAlign: "center", marginTop: "250px", fontSize: "25px" }}
        >
          Loading... Please wait!
        </div>
      ) : (
        <main>
            <div className="row">
              <div className="col-lg-5 col-md-9 col-lg-6">
                <h2 className="mt-30 page-title">Ecom Brand</h2>
              </div>
              
            </div>
            <ol className="breadcrumb mb-30">
              <li className="breadcrumb-item">
                <a href="/">Dashboard</a>
              </li>
              <li className="breadcrumb-item active">Add Area</li>
            </ol>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <Grid container mx={1} my={1.5} spacing={2}>
              <Grid item xs={4}>
                <TextField
                  name="name"
                  required
                  fullWidth
                  size="small"
                  id="Name"
                  label="Name"
                  onChange={(e) => setName(e.target.value)}
                />
              </Grid>
            </Grid>

            <Grid container ml={1} my={1.5} spacing={2}>
              <Grid item xs={4}>
                <TextField
                  name="nameAr"
                  required
                  fullWidth
                  size="small"
                  id="nameAr"
                  label="Name in Arabic"
                  onChange={(e) => setNameAr(e.target.value)}
                />
              </Grid>
            </Grid>

          
            <Grid sx={4}>
              <Button
                type="submit"
                variant="contained"
                sx={{ ml: 3, mt: 2, mb: 2 }}
              >
                Add Area
              </Button>
            </Grid>
          </Box>
        </main>
      )}
    </div>
  );
};

export default AddArea;
