import React, { useEffect, useState } from "react";
import styles from "./Dashboard.module.css";
import Chart from "./Chart";
import Widget from "../../components/widget/Widget";
import apiClient from "../../api/client";
import useAuth from "../../auth/useAuth";

const Dashboard = () => {
  const { user } = useAuth();
  const type = user.type;
  console.log('type', type)
  const [countData, setCountData] = useState({});
  const [monthlySales, setMonthlySales] = useState([]);

  useEffect(() => {
    const fetchAdminData = async () => {
      const endpointMap = {
        admin: "/dashboard/admin-dashboard",
        property: "/dashboard/property-dashboard",
        maintenance: "/dashboard/maintenance-dashboard",
        ecom: "/dashboard/ecom-dashboard",
        company: "/dashboard/company-dashboard",
      };
      const { data } = await apiClient.get(endpointMap[type], {
        sellerId: user.id,
      });
      console.log(data)
      setCountData(data);
      setMonthlySales(data.monthlySales || []);
    };
    fetchAdminData();
  }, [type]);

  const getWidgetConfig = () => {
    const widgetConfig = {
      admin: [
        { type: "ecommerce", count: countData?.ecommerceVendorCount || 0 },
        { type: "company", count: countData?.companiesCount || 0 },
        { type: "property", count: countData?.propertyVendorCount || 0 },
        { type: "maintenance", count: countData?.maintenanceVendorCount || 0 },
        { type: "unapproved", count: countData?.unapproved || 0 },
        { type: "users", count: countData?.users || 0 },
        { type: "properties", count: countData?.properties || 0 },
        { type: "totalSales", count: countData?.totalSales || 0 },
      ],
      property: [{ type: "property", count: countData?.propertyVendorCount || 0 }],
      ecom: [
        { type: "product", count: countData?.productCountBySeller || 0 },
        { type: "totalSales", count: countData?.totalSales || 0 },
      ],
      maintenance: [
        { type: "enquiries", count: countData?.enquiriesCount || 0 },
        { type: "categories", count: countData?.myCategoryCount || 0 },
      ],
      company: [{ type: "categories", count: countData?.myCategoryCount },
      ],
    };
    return widgetConfig[type] || [];
  };

  return (
    <div id="layoutSidenav_content">
      <section className={styles.stat}>
        <ul className={styles.autoGrid}>
          {getWidgetConfig().map((widget) => (
            <Widget key={widget.type} type={widget.type} count={widget.count} />
          ))}
        </ul>
      </section>
     {type === 'ecom' && <section>
        <Chart monthlySales={monthlySales} />
      </section>}
    </div>
  );
};

export default Dashboard;
