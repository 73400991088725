import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import SideNavBar from "./components/SideNavBar";
import AllProducts from "./pages/Products/AllProducts";
import AddProduct from "./pages/Products/AddProduct";
import Dashboard from "./pages/Dashboard/Dashboard";
import useAuth from "./auth/useAuth";
import Login from "./pages/Login";
import Register from "./pages/Register";

import AllEcomSeller from "./pages/Products/AllEcomSeller";
import AddProperty from "./pages/Property/AddProperty";
import AllProperties from "./pages/Property/AllProperties";
import AllManager from "./pages/Property/AllManager";

import AllEnquiry from "./pages/Maintenance/AllEnquiry";
import AllMaintenanceManager from "./pages/Maintenance/AllMaintenanceManager";
import AllCompanies from "./pages/Companies/AllCompanies";
import AddCompanyCat from "./pages/Companies/AddCompanyCat";
import AllCompanyCategory from "./pages/Companies/AllCompanyCat";
import AddEcomCategory from "./pages/Filter/AddEcomCategory";
import AllEcomCategory from "./pages/Filter/AllEcomCategory";
import AddEcomBrand from "./pages/Filter/AddEcomBrand";
import AllEcomBrand from "./pages/Filter/AllEcomBrands";
import AddMaintenanceCat from "./pages/Filter/AddMaintenanceCat";
import AllMaintenanceCat from "./pages/Filter/AllMaintenanceCat";
import Customers from "./pages/Customers/Customers";
import ApproveSeller from "./pages/ApproveSeller";
import AllOrders from "./pages/Orders/AllOrders";
import OrderDetails from "./pages/Orders/OrderDetails";
import ImageBanner from "./pages/Banners/ImageBanner";
import ListImgBanner from "./pages/Banners/ListImgBanner";
import SetCategories from "./pages/Maintenance/SetCategories";
import SetCompCat from "./pages/Companies/SetCompCat";
import EditProduct from "./pages/Products/EditProduct";
import EditProperty from "./pages/Property/EditProperty";
import CompanyDetails from "./pages/Companies/CompanyDetails";
import SendNotification from "./pages/Customers/SendNotification";
import SendNotificationAll from "./pages/Customers/SendNotificationAll";
import OrderSeller from "./pages/Orders/OrderSeller";
import ViewEnquiry from "./pages/Maintenance/ViewEnquiry";
import AllSponsoredProducts from "./pages/Products/AllSponsored";
import AllSponsoredProperties from "./pages/Property/AllSponsoredProperty";
import AllArea from "./pages/Property/AllArea";
import AddArea from "./pages/Property/AddArea";
import EditEcomCategory from "./pages/Filter/EditEcomCategory";
import EditEcomBrand from "./pages/Filter/EditEcomBrands";
import EditMaintenanceCategory from "./pages/Filter/EditMaintenanceCat";
import EditCompanyCat from "./pages/Companies/EditCompanyCat";


function HeaderComponent() {
  const { user } = useAuth();
  if (!user) {
    return (
      <>
        <Routes>
          <Route path="/" element={<Login />}></Route>

          <Route path="/register" element={<Register />}></Route>
        </Routes>
      </>
    );
  }

  return (
    <>
      <Header />
      <div id="layoutSidenav">
        <SideNavBar />
        <Routes>
          <Route path="/" element={<Dashboard />}></Route>

          <Route path="/product/create" element={<AddProduct />}></Route>
          <Route path="/product/edit" element={<EditProduct />}></Route>
          <Route path="/product/list" element={<AllProducts />}></Route>
          <Route path="/product/vendorList" element={<AllEcomSeller />}></Route>
          <Route path="/product/sponsored" element={<AllSponsoredProducts />}></Route>
          <Route path="/property/create" element={<AddProperty />}></Route>
          <Route path="/property/edit" element={<EditProperty />}></Route>
          <Route path="/property/list" element={<AllProperties />}></Route>
          <Route path="/property/vendorList" element={<AllManager />}></Route>
          <Route path="/property/sponsored" element={<AllSponsoredProperties />}></Route>
          <Route path="/property/location" element={<AllArea />}></Route>
          <Route path="/property/location/add" element={<AddArea />}></Route>
          <Route path="/maintenance/enquiry" element={<AllEnquiry />}></Route>
          <Route
            path="/maintenance/manager"
            element={<AllMaintenanceManager />}
          ></Route>
          <Route path="/company/list" element={<AllCompanies />}></Route>
          <Route path="/company/profile" element={<CompanyDetails />}></Route>
          <Route
            path="/company/set-my-categories"
            element={<SetCompCat />}
          ></Route>
        
          <Route
            path="/company/category/create"
            element={<AddCompanyCat />}
          ></Route>
           <Route
            path="/company/category/edit"
            element={<EditCompanyCat />}
          ></Route>
          <Route
            path="/company/category/list"
            element={<AllCompanyCategory />}
          ></Route>
          <Route
            path="/product/category/create"
            element={<AddEcomCategory />}
          ></Route>
          <Route
            path="/product/category/edit"
            element={<EditEcomCategory />}
          ></Route>
          <Route
            path="/product/category/list"
            element={<AllEcomCategory />}
          ></Route>
          <Route
            path="/maintenance/category/create"
            element={<AddMaintenanceCat />}
          ></Route>
          <Route
            path="/maintenance/category/list"
            element={<AllMaintenanceCat />}
          ></Route>
            <Route
            path="/maintenance/category/update"
            element={<EditMaintenanceCategory />}
          ></Route>
          <Route
            path="/maintenance/set-my-categories"
            element={<SetCategories />}
          ></Route>
          <Route
            path="/product/brand/create"
            element={<AddEcomBrand />}
          ></Route>
           <Route
            path="/product/brand/edit"
            element={<EditEcomBrand />}
          ></Route>
          <Route path="/product/brand/list" element={<AllEcomBrand />}></Route>
          <Route path="/customer/list" element={<Customers />}></Route>
          <Route path="/customer/notification" element={<SendNotification />}></Route>
          <Route path="/notification/all" element={<SendNotificationAll />}></Route>
          <Route path="/approve" element={<ApproveSeller />}></Route>
          <Route path="/orders/list" element={<AllOrders />}></Route>
          <Route path="/order/view/:id" element={<OrderDetails />} />
          <Route path="/orders/seller" element={<AllOrders />}></Route>
          <Route path="/banners/image" element={<ListImgBanner />}></Route>
          <Route path="/banners/add" element={<ImageBanner />}></Route>
          <Route path="/enquiry/view" element={<ViewEnquiry />}></Route>
        </Routes>
      </div>
    </>
  );
}

export default HeaderComponent;
