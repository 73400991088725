import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import apiClient from "../../api/client";

import {
  Table,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Button,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import "./table.scss";
import swal from "sweetalert";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import * as XLSX from "xlsx";
import { ArrowBack, DownloadSharp } from "@mui/icons-material";
import useAuth from "../../auth/useAuth";

const AllEnquiry = () => {
  const [isloaded, setIsLoaded] = useState(false);
  const [del, setDel] = useState(false);
  const [getList, setGetList] = useState([]);
  const [pageCount, setPageCount] = useState([]);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const navigate = useNavigate();

  const [brandId, setBrandId] = useState();
  const { user } = useAuth();

  const [outOfStock, setOutOfStock] = useState(false);

  useEffect(() => {
    fetchProducts();
  }, [currentPageNo, del, outOfStock]);

  const fetchProducts = async () => {
    if (user.type === "maintenance") {
      setIsLoaded(true);
      const { data } = await apiClient.get(
        "/maintenance/get-forms-by-manager",
        {
          pageNumber: currentPageNo,
        }
      );
      
      console.log("pageCount", data.pageCount)
      setPageCount(data.pageCount);
      setGetList(data.products);
      setIsLoaded(false);
    } else {
      setIsLoaded(true);
      const { data } = await apiClient.get("/maintenance/get-forms", {
        pageNumber: currentPageNo,
      });
    
      setPageCount(data.pageCount);
      setGetList(data.submittedForm);
      setIsLoaded(false);
    }
  };

  const handlDeleteById = async (id) => {
    swal({
      title: "Are you sure?",
      text: "You want to Delete Product",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (success) => {
      if (success) {
        const result = await apiClient.delete("/ecom/delete-products", { id });
        if (result.ok) {
          swal("Delete Success");
          setDel(!del);
        }
      }
    });
  };

  const exportDataToExcel = async (ob) => {
    const { data } = await apiClient.get("/products/download");

    let arr = [];
    const flattenObj = (ob) => {
      let result = {};
      for (const i in ob) {
        if (typeof ob[i] === "object" && !Array.isArray(ob[i])) {
          const temp = flattenObj(ob[i]);

          delete temp.brand;
          delete temp.image;
          delete temp.email;
          delete temp.mobileNumber;
          delete temp.region;
          delete temp.zone;
          delete temp._id;

          for (const j in temp) {
            result[i + "." + j] = temp[j];
          }
        } else if (
          !Array.isArray(ob[i]) &&
          i != "updatedAt" &&
          i != "taxPrice" &&
          i != "__v"
        ) {
          if (i === "createdAt") {
            result[i] = new Date(ob[i]).toDateString();
          } else {
            result[i] = ob[i];
          }
        }
      }

      return result;
    };

    for (let i = 0; i < data?.length; i++) {
      const newobj = flattenObj(data[i]);
      console.log(newobj);
      arr.push(newobj);
    }

    setTimeout(() => {
      const data1 = arr;
      const worksheet = XLSX.utils.json_to_sheet(data1, {
        header: [
          "_id",
          "hsnCode",
          "groupId",
          "name",
          "brand",
          "size",
          "color",
          "flavour",
          "category",
          "subcategory",
          "specialcategory",
          "cost_price",
          "sell_price",
          "discount",
          "details",
          "description",
          "image",
          "weight",
          "countInStock",
          "manufacturer",
          "notes",
          "foodType",
          "limit",
          "shelflife",
          "fssai",
          "country",
          "expiry",
        ],
      });
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, "ProductSheet.xlsx");
    }, 1000);
  };

  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid">
          <Grid container my={2} spacing={2}>
            <Grid item xs={9}>
              <h2 className="">Enquiries</h2>
            </Grid>
            <Grid item alignContent={"end"} alignSelf={"end"} xs={1.5}>
              <Button
                onClick={(e) => navigate("/")}
                size="small"
                variant="contained"
                endIcon={<ArrowBack />}
              >
                Back
              </Button>
            </Grid>
            <Grid item alignSelf={"end"} xs={1.5}>
              <Button
                size="small"
                variant="contained"
                endIcon={<DownloadSharp />}
              >
                Download
              </Button>
            </Grid>
          </Grid>

          <TableContainer component={Paper} className="table">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className="tableCell">ID</TableCell>
                  <TableCell className="tableCell">Category</TableCell>
                  <TableCell className="tableCell">User</TableCell>

                  <TableCell className="tableCell">Date & Time</TableCell>

                  <TableCell className="tableCell">View</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {getList?.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell className="tableCell">{row._id}</TableCell>
                    <TableCell className="tableCell">
                      {row.maintenanceCategory.name}
                    </TableCell>
                    <TableCell className="tableCell">
                      {row.user?.name}
                    </TableCell>

                    <TableCell className="tableCell">
                      {new Date(row?.dateTime).toDateString()},{" "}
                      {new Date(row?.dateTime).toTimeString()}
                    </TableCell>
                    <TableCell className="tableCell">
                      <Link to="/enquiry/view" state={row}>
                        <Typography className="edit-btn">
                          <i className="fas fa-edit" />
                        </Typography>
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            count={pageCount}
            color="primary"
            onChange={(e, value) => setCurrentPageNo(value)}
          />
        </div>
      </main>
    </div>
  );
};

export default AllEnquiry;
