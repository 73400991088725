import React, { useState, useEffect, useRef, useMemo } from "react";
// import { Button } from "@material-ui/core";
import swal from "sweetalert";
import apiClient from "../../api/client";
import { useNavigate } from "react-router-dom";
import {
  Autocomplete,
  Box,
  Grid,
  InputLabel,
  TextField,
  Button,
} from "@mui/material";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { QuillFormats } from "../../components/editor/quill";
import useAuth from "../../auth/useAuth";
import GoogleMapReact from "google-map-react";
import LocationOnIcon from '@mui/icons-material/LocationOn';

const AddProperty = () => {
  const { user } = useAuth();
  const quillRef = useRef();
  const [description, setDescription] = useState();
  const [details, setDetails] = useState();
  const [descriptionAr, setDescriptionAr] = useState();
  const [detailsAr, setDetailsAr] = useState();
  const [type, setType] = useState();
  const [area, setArea] = useState();
  const [areaId, setAreaId] = useState();
  const [propertyType, setPropertyType] = useState();
  const [rooms, setRooms] = useState();
  const [bathroom, setBathroom] = useState();
  const [status, setStatus] = useState("Available");
  // const [lang, setLang] = useState(Number(51.5310));
  // const [lat, setLat] = useState(Number(25.2854));
  const [location, setLocation] = useState({ latitude: 25.2854, longitude: 51.5310 });
  const [zoom, setZoom] = useState(Number(10));
  const [file2, setFile2] = useState([]);
  const [seller, setSeller] = useState();
  const [sellerId, setSellerId] = useState(
    user.type == "property" ? user.id : ""
  );


  const onMapClick = (e) => {
     
      setLocation({ latitude: e.lat, longitude: e.lng })
     
    };
  const navigate = useNavigate();

  const [state, setState] = useState({
    sku: "",
    name: "",
    nameAr: "",

    price: "",
    size: "",
  });

  useEffect(() => {
    fetchSellers();
    fetchAreas()
  }, []);
  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ["bold", "italic", "underline", "strike"],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          ["link"],
          [
            {
              color: [
                "#000000",
                "#e60000",
                "#ff9900",
                "#ffff00",
                "#008a00",
                "#0066cc",
                "#9933ff",
                "#ffffff",
                "#facccc",
                "#ffebcc",
                "#ffffcc",
                "#cce8cc",
                "#cce0f5",
                "#ebd6ff",
                "#bbbbbb",
                "#f06666",
                "#ffc266",
                "#ffff66",
                "#66b966",
                "#66a3e0",
                "#c285ff",
                "#888888",
                "#a10000",
                "#b26b00",
                "#b2b200",
                "#006100",
                "#0047b2",
                "#6b24b2",
                "#444444",
                "#5c0000",
                "#663d00",
                "#666600",
                "#003700",
                "#002966",
                "#3d1466",
              ],
            },
          ],
        ],
      },
    }),
    []
  );

  const fetchSellers = async () => {
    const { data } = await apiClient.get("/property/get-all-sellers");

    setSeller(data);
  };
  const fetchAreas = async () => {
    const { data } = await apiClient.get("/misc/area");

    setArea(data);
  };

  const uploadFileHandler = async (e) => {
    setFile2([...file2, ...e.target.files]);
  };

  function handleChange(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    swal({
      title: "Are you sure?",
      text: "You want to Add New Property",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (success) => {
      if (success) {
        const img = await handlesubmit2();
       
        const result = await apiClient.post("/property/create", {
          name: state.name,
          nameAr: state.nameAr,
          image: img,
          type: type,
          area: areaId,
          propertyType: propertyType,
          bathroom: bathroom,
          rooms: rooms,
          description: description,
          details: details,
          descriptionAr: descriptionAr,
          detailsAr: detailsAr,
          propertyManager: sellerId,
          status,
          size: state.size,
          price: state.price,
          location: location,
        });
       
        if (result.ok) {
          setState({
            name: "",
            nameAr: "",
            image: "",
            price: "",
            size: "",
          });
          swal("Success Property added");
          navigate("/property/list");
        }
      }
    });
  };

  const handlesubmit2 = async () => {
    if (file2) {
      const formData = new FormData();

      for (const file of file2) {
        formData.append("image", file, file.name);
      }

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const result = await apiClient.post(
        "/upload/uploadMultiple",
        formData,
        config
      );

      if (result.ok) {
        setFile2([]);
        return result.data;
      } else {
        swal("Error Retry!");
      }
    }
  };

  const seloptions = seller?.map((guest, index) => {
    return {
      label: guest.name,
      value: guest._id,
      key: index,
    };
  });
  const areaoptions = area?.map((guest, index) => {
    return {
      label: guest.name,
      value: guest._id,
      key: index,
    };
  });
  const defaultProps = {
    center: {
      lat: location.latitude,
      lng: location.longitude,
    },
    zoom: zoom,
  };
  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-5 col-md-9 col-lg-6">
              <h2 className="mt-30 page-title">Properties</h2>
            </div>
            <div className="col-lg-5 col-md-3 col-lg-6 back-btn">
              <Button variant="contained" onClick={(e) => navigate('/')}>
                <i class="fas fa-arrow-left" /> Back
              </Button>
            </div>
          </div>
          <ol className="breadcrumb mb-30">
            <li className="breadcrumb-item">
              <a href="/">Dashboard</a>
            </li>
            <li className="breadcrumb-item">
              <a href="/admin/product/create">Property</a>
            </li>
            <li className="breadcrumb-item active">Add Property</li>
          </ol>

          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <Grid container my={1.5} spacing={2}>
              <Grid item xs={3}>
                <TextField
                  name="name"
                  required
                  fullWidth
                  size="small"
                  id="name"
                  label="Title"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  name="nameAr"
                  required
                  fullWidth
                  size="small"
                  id="nameAr"
                  label="Title in Arabic"
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  name="price"
                  required
                  fullWidth
                  type="number"
                  size="small"
                  id="price"
                  label="Price"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  name="size"
                  required
                  type="number"
                  fullWidth
                  size="small"
                  id="size"
                  label="Area Sq feet"
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Grid container my={1.5} spacing={2}>
              <Grid item xs={3}>
                <Autocomplete
                  required
                  disablePortal
                  id="combo-box-demo"
                  options={["Rent", "Sell"]}
                  onChange={(event, newValue) => {
                    setType(newValue);
                  }}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField required {...params} label="Purpose" />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  required
                  disablePortal
                  id="combo-box-demo"
                  options={[
                    "House",
                    "Apartment",
                    "Villa",
                    "Office",
                    "Commercial Building",
                    "Land"
                  ]}
                  onChange={(event, newValue) => {
                    setPropertyType(newValue);
                  }}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField required {...params} label="Property Type" />
                  )}
                />
              </Grid>

              {user.type == "admin" ? (
                <Grid item xs={3}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={seloptions}
                    onChange={(event, newValue) => {
                      setSellerId(newValue.value);
                    }}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Seller" />
                    )}
                  />
                </Grid>
              ) : (
                <></>
              )}

              <Grid item xs={3}>
                <Autocomplete
                  required
                  disablePortal
                  id="combo-box-demo"
                  options={[
                    "On Hold",
                    "Available",
                    "Booked",
                    "Sold",
                    "Unavailable",
                  ]}
                  onChange={(event, newValue) => {
                    setStatus(newValue);
                  }}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField required {...params} label="Status" />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={areaoptions}
                    onChange={(event, newValue) => {
                      setAreaId(newValue.value);
                    }}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Area" />
                    )}
                  />
                </Grid>
            </Grid>
            <Grid container mt={2.5} spacing={2}>
              <Grid item xs={3}>
                <TextField
                  name="bathroom"
                  required
                  type="number"
                  fullWidth
                  size="small"
                  id="size"
                  label="Bathroom"
                  onChange={(e) => setBathroom(e.target.value)}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  name="rooms"
                  required
                  type="number"
                  fullWidth
                  size="small"
                  id="size"
                  label="Number of Rooms"
                  onChange={(e) => setRooms(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container my={1.5} spacing={2}>
              <Grid item xs={12}>
                <Button type="button" variant="contained">
                  Select Location
                </Button>
                <div style={{ height: '50vh', width: '60%' }}>
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: "AIzaSyBLxrP9QbIFgVjPTdO5UfnmFXpWmdqFyr0",
                  }}
                  defaultCenter={defaultProps.center}
                  defaultZoom={defaultProps.zoom}
                  onClick={onMapClick}
                  lat={location.latitude}
                  lng={location.longitude}
                  center={defaultProps.center}
                  zoom={defaultProps.zoom}
                >
                  <LocationOnIcon
                    lat={location.latitude}
                    lng={location.longitude}
                  />
                </GoogleMapReact>
                </div>
               
              </Grid>
            </Grid>
            <Grid container my={1.5} spacing={2}>
              <Grid item xs={10}>
                <InputLabel sx={{ fontWeight: 600 }}>Description</InputLabel>
                <ReactQuill
                  theme="snow"
                  formats={QuillFormats}
                  ref={quillRef}
                  value={description}
                  modules={modules}
                  onChange={setDescription}
                  placeholder="Write description..."
                />
              </Grid>
            </Grid>
            <Grid container my={1.5} spacing={2}>
              <Grid item xs={10}>
                <InputLabel sx={{ fontWeight: 600 }}>Details</InputLabel>
                <ReactQuill
                  theme="snow"
                  formats={QuillFormats}
                  ref={quillRef}
                  value={details}
                  modules={modules}
                  onChange={setDetails}
                  placeholder="Write Details..."
                />
              </Grid>
            </Grid>
            <Grid container my={1.5} spacing={2}>
              <Grid item xs={10}>
                <InputLabel sx={{ fontWeight: 600 }}>
                  Description in Arabic
                </InputLabel>
                <ReactQuill
                  theme="snow"
                  formats={QuillFormats}
                  ref={quillRef}
                  value={descriptionAr}
                  modules={modules}
                  onChange={setDescriptionAr}
                  placeholder="Write description in Arabic..."
                />
              </Grid>
            </Grid>
            <Grid container my={1.5} spacing={2}>
              <Grid item xs={10}>
                <InputLabel sx={{ fontWeight: 600 }}>
                  Details in Arabic
                </InputLabel>
                <ReactQuill
                  theme="snow"
                  formats={QuillFormats}
                  ref={quillRef}
                  value={detailsAr}
                  modules={modules}
                  onChange={setDetailsAr}
                  placeholder="Write Details in Arabic..."
                />
              </Grid>
            </Grid>

            <Grid container mt={2.5} spacing={2}>
              <Grid item xs={10}>
                <input type="file" multiple onChange={uploadFileHandler} />
              </Grid>
            </Grid>
            <Grid sx={4}>
              <Button type="submit" variant="contained" sx={{ mt: 2, mb: 2 }}>
                Add Property
              </Button>
            </Grid>
          </Box>
        </div>
      </main>
    </div>
  );
};

export default AddProperty;
