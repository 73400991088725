import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Grid,
  Button,
  Typography,
} from "@mui/material";
import apiClient from "../../api/client";

const ViewEnquiry = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state;
console.log(data)
  const [amount, setAmount] = useState();

  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-5 col-md-9 col-lg-6">
              <h2 className="mt-30 page-title">Enquiry</h2>
            </div>
            <div className="col-lg-5 col-md-3 col-lg-6 back-btn">
              <Button variant="contained" onClick={() => navigate(-1)}>
                <i className="fas fa-arrow-left" /> Back
              </Button>
            </div>
          </div>
          <ol className="breadcrumb mb-30">
            <li className="breadcrumb-item">
              <a href="/">Dashboard</a>
            </li>
          </ol>

          <div>
            <Grid container my={1.5} spacing={2}>
              <Grid item xs={8}>
                <Typography>Category: {data?.maintenanceCategory?.name}
              </Typography>
                <Typography>Customer Name: {data?.user?.name}
              </Typography>
                <Typography>Date: {new Date(data?.dateTime).toDateString()},{" "}
              </Typography>
                <Typography> Time: {new Date(data?.dateTime).toTimeString()}
              </Typography>
                <Typography> Description:{<div dangerouslySetInnerHTML={{ __html: (data?.description) }} /> }

              </Typography>
              <div>

              <img height={300} src={data?.image[0]}></img>
              </div>
              <div>
                Location Map
              </div>
              </Grid>
              <Grid item xs={3}></Grid>
            </Grid>
            <Grid sx={4}></Grid>
          </div>
        </div>
      </main>
    </div>
  );
};

export default ViewEnquiry;
