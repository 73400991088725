import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import apiClient from "../../api/client";
import {
  Table,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Button,
  Typography,
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import swal from "sweetalert";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { ArrowBack } from "@mui/icons-material";
import useAuth from "../../auth/useAuth";
import '../Companies/table.scss';

const AllOrders = () => {
  const { user } = useAuth();
  const [isloaded, setIsLoaded] = useState(false);
  const [del, setDel] = useState(false);
  const [getList, setGetList] = useState([]);
  const [pageCount, setPageCount] = useState([]);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    fetchCategories();
  }, [currentPageNo, del]);

  const fetchCategories = async () => {
    if (user.type === "admin") {
      const { data } = await apiClient.get("/order");
      setGetList(data?.orders);
      setPageCount(data?.pageCount);
    } else {
      console.log(user)
      const { data } = await apiClient.get("/order/byseller", {
        sellerId: user.id
      });
      console.log(data)
      setGetList(data.orders);
      setPageCount(data?.pageCount);
    }
  };

  const handlDeleteById = async (id) => {
    swal({
      title: "Are you sure?",
      text: "You want to Delete Product",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (success) => {
      if (success) {
        console.log(user)
        const result = await apiClient.delete("/order/delete-order", { id });
        if (result.ok) {
          swal("Delete Success");
          setDel(!del);
        }
      }
    });
  };

  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid">
          <Grid container my={2} spacing={2}>
            <Grid item xs={9}>
              <h2>All Orders</h2>
            </Grid>
            <Grid item alignContent={"end"} alignSelf={"end"} xs={1.5}>
              <Button
                onClick={() => navigate("/")}
                size="small"
                variant="contained"
                endIcon={<ArrowBack />}
              >
                Back
              </Button>
            </Grid>
          </Grid>

          <TableContainer component={Paper} className="table">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className="tableCell">Name</TableCell>
                  <TableCell className="tableCell">Total Price</TableCell>
                  <TableCell className="tableCell">Items</TableCell>
                  <TableCell className="tableCell">Payment Method</TableCell>
                  <TableCell className="tableCell">Delivery Status</TableCell>
                  <TableCell className="tableCell">View</TableCell>
                  <TableCell className="tableCell">Edit</TableCell>
                  <TableCell className="tableCell">Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {getList?.map((row) => (
                  <TableRow key={row._id}>
                    <TableCell className="tableCell">{row?.user?.name}</TableCell>
                    <TableCell className="tableCell">{row.totalPrice}</TableCell>
                    <TableCell className="tableCell">
                      {row.orderItems.map((item) => (
                        <Typography key={item._id}>{item.name}, </Typography>
                      ))}
                    </TableCell>
                    <TableCell className="tableCell">{row.paymentMethod}</TableCell>
                    <TableCell className="tableCell">{row.deliveryStatus}</TableCell>
                    <TableCell className="tableCell">
                      <Link to={`/order/view/${row._id}`}>
                        <Button variant="outlined" color="primary">
                          View
                        </Button>
                      </Link>
                    </TableCell>
                    <TableCell className="tableCell">
                      <Link to="/order/edit" state={row}>
                        <Typography className="edit-btn">
                          <EditIcon />
                        </Typography>
                      </Link>
                    </TableCell>
                    <TableCell className="tableCell">
                      <Typography
                        className="edit-btn"
                        onClick={() => handlDeleteById(row._id)}
                      >
                        <DeleteIcon />
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            count={pageCount}
            color="primary"
            onChange={(e, value) => setCurrentPageNo(value)}
          />
        </div>
      </main>
    </div>
  );
};

export default AllOrders;
