import React, { useState } from "react";
import { Button, MenuItem, OutlinedInput, Select } from "@material-ui/core";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import apiClient from "../../api/client";

const ImageBanner = () => {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const [name, setName] = useState();
  const [description, setDescription] = useState();
  const [photo, setPhoto] = useState();
  const [bg, setBg] = useState();
  const [link, setLink] = useState();
  const [productId, setProductId] = useState();
  const [companyId, setCompanyId] = useState();
  const [maintenanceId, setMaintenanceId] = useState();
  const [propertyId, setPropertyId] = useState();
  const opt1 = [
    "Product",
    "Company",
    "Maintenance Company",
    "Property Manager",
  ];
  const [opt, setOpt] = useState();

  const handleSelect1 = async (e) => {
    setCompanyId();
    setMaintenanceId();
    setPropertyId();
    setOpt(e);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    swal({
      title: "Are you sure?",
      text: "You want to Add New Banner",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (success) => {
      if (success) {
        const formData = new FormData();

        formData.append("image", photo, photo.name);

        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };

        const { data } = await apiClient.post(
          "/upload/uploadSingleImage",
          formData,
          config
        );

        const result = await apiClient.post("/misc/banner", {
          name,

          image: data,

          link,
          product: productId,
          company: companyId,
          maintenance: maintenanceId,
          property: propertyId,
        });
        if (result.ok) {
          setName("");
          setDescription("");
          setPhoto("");
          setBg("");
          swal("Sucess");
          navigate("/banners/image");
        } else {
          swal("Error Retry!");
        }
      }
    });
  };

  const navigate = useNavigate();

  const uploadFileHandler = async (e) => {
    setPhoto(e.target.files[0]);
  };

  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-5 col-md-9 col-lg-6">
              <h2 className="mt-30 page-title">Category</h2>
            </div>
            <div className="col-lg-5 col-md-3 col-lg-6 back-btn">
              <Button
                variant="contained"
                onClick={(e) => navigate("/banners/image")}
              >
                <i class="fas fa-arrow-left" /> Back
              </Button>
            </div>
          </div>
          <ol className="breadcrumb mb-30">
            <li className="breadcrumb-item">
              <a href="/">Dashboard</a>
            </li>
            <li className="breadcrumb-item">
              <a href="/admin/product/create">Banners</a>
            </li>
            <li className="breadcrumb-item active">Image</li>
          </ol>

          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="card card-static-2 mb-30">
                <div className="card-title-2">
                  <h4>Add New Banner</h4>
                </div>
                <div className="card-body-table">
                  <div className="news-content-right pd-20">
                    <div className="col-lg-4 col-md-2">
                      <div className="form-group">
                        <label className="form-label">Title*</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Title"
                          name="name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                    </div>

                    <br />
                    <div className="col-lg-3 col-md-2">
                      <div className="form-group">
                        <label className="form-label">Select Image </label>
                        <form>
                          <input type="file" onChange={uploadFileHandler} />
                        </form>
                      </div>
                    </div>
                    <br />
                    <div className="col-lg-4 col-md-4">
                      <div className="form-group">
                        <label className="form-label">Select Banner Type</label>
                        <Select
                          className="form-control"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={opt}
                          label="Select Banner Type"
                          onChange={(e) => handleSelect1(e.target.value)}
                          MenuProps={MenuProps}
                        >
                          {opt1?.map((item, index) => {
                            return <MenuItem value={item}>{item}</MenuItem>;
                          })}
                        </Select>
                      </div>
                    </div>
                    {opt == "Company" ? (
                      <div className="col-lg-6 col-md-2 ">
                        <div className="form-group">
                          <label className="form-label">
                            Add Category's -Id
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Category Id"
                            name="categoryId"
                            value={companyId}
                            onChange={(e) => setCompanyId(e.target.value)}
                          />
                        </div>
                      </div>
                    ) : opt == "Maintenance Company" ? (
                      <div className="col-lg-6 col-md-2 ">
                        <div className="form-group">
                          <label className="form-label">
                            Add Sub Category-Id
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Sub Category Id"
                            name="subCategoryId"
                            value={maintenanceId}
                            onChange={(e) => setMaintenanceId(e.target.value)}
                          />
                        </div>
                      </div>
                    ) : opt == "Property Manager" ? (
                      <div className="col-lg-6 col-md-2 ">
                        <div className="form-group">
                          <label className="form-label">
                            Add Special Category-Id
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Special Category Id"
                            name="specialcategoryId"
                            value={productId}
                            onChange={(e) =>
                              setPropertyId(e.target.value)
                            }
                          />
                        </div>
                      </div>
                    ) : opt == "Product" ? (
                      <div className="col-lg-6 col-md-2 ">
                        <div className="form-group">
                          <label className="form-label">
                            Add Product's Group-Id
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Group Id"
                            name="description"
                            value={productId}
                            onChange={(e) => setProductId(e.target.value)}
                          />
                        </div>
                      </div>
                    ) : (
                      <br />
                    )}

                    <br />

                    <button
                      className="save-btn hover-btn"
                      type="submit"
                      onClick={handleSubmit}
                    >
                      Add New
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default ImageBanner;
