import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "@material-ui/core";
import apiClient from "./../../api/client";
import swal from "sweetalert";
import { Typography } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import * as XLSX from "xlsx";

const Customers = () => {
  const [isloaded, setIsLoaded] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [getList, setGetList] = useState([]);
  const [pageCount, setPageCount] = useState([]);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [count, setCount] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    fetchCategories();
  }, [refresh, currentPageNo]);

  const fetchCategories = async () => {
    setIsLoaded(true);
    const { data } = await apiClient.get("/user", {pageNumber: currentPageNo,});
    setPageCount(data.pageCount);
    setGetList(data.users);
    setCount(data.count);
    setIsLoaded(false);
  };

  const handleDealer = async (item) => {
    swal({
      title: "Are you sure?",
      text: "You want to Assign ",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (success) => {
      if (success) {
        const result = await apiClient.post("/users/assigndealer", {
          userId: item._id,
        });

        const result2 = await apiClient.post("/sendNoti/send-notification", {
          token: item?.pushToken,
          title: "Welcome to Subhash Super Store",
          body: "Hurray! you have been assigned as Dealer",
        });
        if (result.ok) {
          setRefresh(!refresh);
          swal("Dealer Assigned");
        } else {
          swal("Dealer Not Assigned");
        }
      }
    });
  };
  const handleDelete = async (item) => {
    swal({
      title: "Are you sure?",
      text: "You want to Delete ",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (success) => {
      if (success) {
        const result = await apiClient.delete(`/user/delete-user/${item._id}`);
   
        if (result.ok) {
          setRefresh(!refresh);
          swal("Deleted");
        } else {
          swal("Error");
        }
      }
    });
  };

  const exportDataToExcel = async (ob) => {
    const { data } = await apiClient.get("/users/download");
  
    let arr = [];
    const flattenObj = (ob) => {
      let result = {};
      for (const i in ob) {
        if (typeof ob[i] === "object" && !Array.isArray(ob[i])) {
          const temp = flattenObj(ob[i]);

          
          // delete temp.image;
          // delete temp.email;
          // delete temp.mobileNumber;
          // delete temp.region;
          // delete temp.zone;
          // delete temp.createdAt;

          for (const j in temp) {
            result[i + "." + j] = temp[j];
          }

          // result[i] = JSON.stringify(ob[i]);
        } else if (
          !Array.isArray(ob[i]) &&
          i != "updatedAt" &&
          i != "__v"
        ) {
          // const nestedArr = ob[i];
          // nestedArr.forEach((obj, index) => {
          //   delete obj.image;
          //   const abc = flattenObj(obj);
          //   result[i + "." + index] = JSON.stringify(abc);
          // });
          delete ob.isAdmin;
          delete ob.isDealer;
          
          if (i === "createdAt") {
            result[i] = new Date(ob[i]).toDateString();
          } else {
            result[i] = ob[i];
          }
        }
      }

      return result;
    };

    for (let i = 0; i < data?.length; i++) {
      const newobj = flattenObj(data[i]);
      arr.push(newobj);
    }

    setTimeout(() => {
      const data1 = arr;
      const worksheet = XLSX.utils.json_to_sheet(data1, {
        header: [
          "_id",
          "name",
          "phone",
          "shippingAddress.address",
          "shippingAddress.street",
          "shippingAddress.area",
          "shippingAddress.landmark",
          "shippingAddress.pincode",
          "shippingAddress.city",
          
         
          
        ],
      });
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, "CustomerSheet.xlsx");
    }, 1000);
  };

  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-5 col-md-9 col-lg-6">
              <h2 className="mt-30 page-title">Customers</h2>
            </div>
            <div className="col-lg-5 col-md-3 col-lg-6 back-btn">
              <Button variant="contained" onClick={(e) => navigate("/")}>
                <i className="fas fa-arrow-left" /> Back
              </Button>
            </div>
          </div>
          <ol className="breadcrumb mb-30">
            <li className="breadcrumb-item">
              <a href="index.html">Dashboard</a>
            </li>
            <li className="breadcrumb-item active">Customers</li>
          </ol>
          <div className="col-lg-12 col-md-12">
            <div className="card card-static-2 mt-30 mb-30">
              <div className="card-title-2">
                <h4>All Customers({count})</h4>
                <button
              className="save-btn hover-btn"
              type="submit"
              onClick={() => {
                exportDataToExcel();
              }}
            >
              Download Data
            </button>
              </div>
              
              <div className="card-body-table">
                <div className="table-responsive">
                  <table className="table ucp-table table-hover">
                    <thead>
                      <tr>
                        <th style={{ width: 60 }}>No.</th>
                        <th>Name</th>
                        {/* <th>Email</th> */}
                        <th>Mobile</th>
                        <th>Send Notification</th>
                        <th>Assign Dealer</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isloaded !== true
                        ? getList.map((row, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>

                              <td>{row.name}</td>
                              {/* <td>{row.email}</td> */}

                              <td>{row.phone}</td>
                              <td className="action-btns">
                                <Link to="/customer/notification" state={row}>
                                  <Typography className="edit-btn">
                                    🔔
                                  </Typography>
                                </Link>
                              </td>
                              <td>
                                <Typography
                                  className="delete-btn"
                                  onClick={(e) => handleDealer(row)}
                                >
                                  <i className="fas fa-edit" />
                                </Typography>
                              </td>
                              <td>
                                <Typography
                                  className="delete-btn"
                                  onClick={(e) => handleDelete(row)}
                                >
                                  <i className="fas fa-trash-alt" />
                                </Typography>
                              </td>
                            </tr>
                          ))
                        : "Loading..."}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <Pagination
            count={pageCount}
            color="primary"
            onChange={(e, value) => setCurrentPageNo(value)}
          />
        </div>
      </main>
    </div>
  );
};

export default Customers;
