import { BrowserRouter as Router } from "react-router-dom";

import { useEffect, useState } from "react";

import AuthContext from "./auth/context";
import jwtDecode from "jwt-decode";
import HeaderComponent from "./HeaderComponent";
import { ErrorBoundary } from 'react-error-boundary'


function MyFallbackComponent({ error, resetErrorBoundary }) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre style={{ color: "red" }}>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  )
}

function App() {
  const [user, setUser] = useState();

  const restoreUser = async () => {
    const user = await sessionStorage.getItem("token");
    if (user) setUser(jwtDecode(user));
   
  };

  useEffect(() => {
    restoreUser();
  }, []);
 
  return (
    <ErrorBoundary 
    FallbackComponent={MyFallbackComponent}
    onReset={() =>  window.location.href = '/'}
    resetKeys={['someKey']}>

  
    <AuthContext.Provider value={{ user, setUser }}>
      <Router>
        <HeaderComponent />
      </Router>
    </AuthContext.Provider>
    </ErrorBoundary>
  );
}

export default App;
