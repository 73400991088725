import React, { useEffect, useState } from "react";

import swal from "sweetalert";
import {
  Autocomplete,
  BottomNavigationAction,
  Box,
  Fab,
  Grid,
  InputLabel,
  TextField,
  Button,
  Typography,
  List,
  Table,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import apiClient from "./../../api/client";
import useAuth from "../../auth/useAuth";
import { DeleteForeverSharp } from "@mui/icons-material";

const SetCategories = () => {
  const { user } = useAuth();
  const [initial, setInitial] = useState([]);
  const [final, setFinal] = useState([]);
  const [cat, setCat] = useState([]);

  const [modal, setModal] = useState(false);
  useEffect(() => {
    getmyCategories();
    getAllCategories();
  }, []);
  const getmyCategories = async () => {
    const result = await apiClient.get("maintenance/get-my-categories", {
      id: user.id,
    });

    setInitial(result.data);
    const a = result.data.map((object) => object._id);
   
    setFinal(a)
  };
  const getAllCategories = async () => {
    const result = await apiClient.get("maintenance/get-categories");

    setCat(result.data);
  };
  const handleChange = async (e) => {
    const a = e.map((object) => object.value);
   
    setFinal((final) => [...final, ...a]);
  };


  const handleDelete = async (e) => {
    const b = initial?.map((object) => object._id);
    const a = b.filter((i) => {
      return i !== e;
    });

    const result = await apiClient.delete("/maintenance/del-my-categories", {
      id: user.id,
      category: a,
    });
    if (result.ok) {
      window.location.reload();
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    swal({
      title: "Are you sure?",
      text: "You want to Update Your Categories ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (success) => {
      if (success) {
        setModal(true);

        const result = await apiClient.post("/maintenance/set-my-categories", {
          id: user.id,
          category: final,
        });
        if (result.ok) {
          setModal(false);

          swal("Sucess");
          navigate("/");
        } else {
          setModal(false);
          swal("Error Retry!");
        }
      }
    });
  };

  const navigate = useNavigate();
  let catoptions = [];
  catoptions = cat?.map((guest, index) => {
    return {
      label: guest.name,
      value: guest._id,
      key: index,
    };
  });

  return (
    <div id="layoutSidenav_content">
      {modal ? (
        <div
          style={{ textAlign: "center", marginTop: "250px", fontSize: "25px" }}
        >
          Loading... Please wait!
        </div>
      ) : (
        <main>
          <div className="row">
            <div className="col-lg-5 col-md-9 col-lg-6">
              <h2 className="mt-30 page-title">My Category</h2>
            </div>
            <div className="col-lg-5 col-md-3 col-lg-6 back-btn">
              <Button
                variant="contained"
                onClick={(e) => navigate("/category/list")}
              >
                <i class="fas fa-arrow-left" /> Back
              </Button>
            </div>
          </div>
          <ol className="breadcrumb mb-30">
            <li className="breadcrumb-item">
              <a href="/">Dashboard</a>
            </li>
            <li className="breadcrumb-item active">Update Ecom Category</li>
          </ol>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <Grid container mx={1} my={1.5} spacing={2}>
              <Grid item xs={4}>
                <Typography>Add To List</Typography>
                {catoptions?.length >= 0 && (
                  <Autocomplete
                    multiple
                    id="combo-box-demo"
                    options={catoptions}
                    onChange={(e, ne) => handleChange(ne)}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField required {...params} label="All Categories" />
                    )}
                  />
                )}
              </Grid>
              <Grid item xs={8}>
                <Typography>My Categories</Typography>
                <List>
                  <TableContainer component={Paper} className="table">
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell className="tableCell">Category</TableCell>
                          <TableCell className="tableCell">Delete</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {initial?.map((row) => (
                          <TableRow key={row.id}>
                            <TableCell className="tableCell">
                              {row.name}
                            </TableCell>
                            <TableCell className="tableCell">
                              <Typography
                                className="edit-btn"
                                onClick={(e) => handleDelete(row._id)}
                              >
                                <i className="fas fa-trash" />
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </List>
              </Grid>
              <Button
                type="button"
                onClick={handleSubmit}
                variant="contained"
                sx={{ mt: 2, mb: 2 }}
              >
                Update
              </Button>
            </Grid>
          </Box>
        </main>
      )}
    </div>
  );
};

export default SetCategories;
